import React, { useContext } from "react";
import { Col, Row, Spinner } from "reactstrap";
import HeaderLogo from "src/components/HeaderLogo";
import { ErrorType, RequestStatus } from "src/lib/api/types";
import { useApi } from "src/lib/ApiProvider";
import { toQueryParms } from "src/utils";

import { prop, reverse, sortBy } from "ramda";
import { Navigate } from "react-router-dom";
import { Footer } from "src/components/Footer";
import { isErrorResponse } from "src/lib/api/utils";
import { ReceiptsContext } from "src/lib/receiptsContext";
import ErrorMessage from "./ErrorMessage";
import { ReceiptsForm } from "./ReceiptsForm";
import { ReceiptFormValues } from "./types";

export const RequestReceiptsForm = () => {
  const { receiptsStatus, setReceipts, setReceiptsStatus } =
    useContext(ReceiptsContext);
  const [emailStatus, setEmailStatus] = React.useState<RequestStatus>("idle");
  const [errorType, setErrorType] = React.useState<ErrorType | null>(null);

  const { api, authApi } = useApi();

  const handleRegisterEmail = React.useCallback(
    async (sessionToken: string, email: string) => {
      authApi
        .patch(
          `api/customer/v2/customers/me`,
          {
            email,
          },
          { headers: { authorization: `Bearer ${sessionToken}` } } // token has to be explicitely passed as it can't be taken from urlParams
        )
        .then((res) => {
          if (res.status === 200) {
            setEmailStatus("success");
          }
        })
        .catch((err) => {
          setEmailStatus("error");
          console.error(err);
        });
    },
    [authApi]
  );
  const handleSubmit = React.useCallback(
    async (values: ReceiptFormValues) => {
      setReceiptsStatus("loading");
      setErrorType(null);
      try {
        const response = await api.get(
          `api/receipts?${toQueryParms(
            values as unknown as Record<string, string>
          )}`
        );
        setReceipts(reverse(sortBy(prop("draftCreatedAt"), response.data)));

        if (values.email) {
          if (response.data.length > 0) {
            const sessionToken = response.data[0].sessionToken;
            const email = values.email!;
            // Note: If registration will fail, this won't be reflected in the interface as user
            // will be redirected to receipts list. While working on related issue I decided not to
            // refactor this part as I believe it's better to show the receipts than to stop whole
            // customer from retrieving the receipt. See OASIS-18820 for improvement ticket.
            handleRegisterEmail(sessionToken, email);
          } else {
            setEmailStatus("error");
          }
        }
        setReceiptsStatus("success");
      } catch (err) {
        setReceiptsStatus("error");
        if (isErrorResponse(err)) {
          setErrorType(err.response.data.type);
        } else {
          setErrorType(ErrorType.UNRECOGNIZED_ERR);
        }
      }
    },
    [api, handleRegisterEmail, setReceipts, setReceiptsStatus]
  );
  if (receiptsStatus === "success") {
    setReceiptsStatus("idle");
    return <Navigate to="/list" replace={false} />;
  }
  return (
    <div
      style={{
        padding: "16px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderLogo />
      <Col>
        <ReceiptsForm onSubmit={handleSubmit} emailStatus={emailStatus} />
        {receiptsStatus === "loading" && (
          <Row className="justify-content-center">
            <Spinner />
          </Row>
        )}
      </Col>
      {receiptsStatus === "error" && errorType !== null && (
        <Col>
          <Row className="justify-content-center">
            <ErrorMessage errorType={errorType} />
          </Row>
        </Col>
      )}
      <Footer />
    </div>
  );
};
