/**
 * English translation is a reference translation.
 */
export const en = {
  messageFallbacks: {
    homeOrderNotReadyMsg:
      'Just left the store? Your receipt is being processed, please check back in a while.<br/>In case of any issues, reach out for support at <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
    errorReachOutSupportMsg:
      'In case of any issues, reach out for support at <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
  },
  api: {
    E_FORCED_LOGOUT: "You have been logged out your connection expired.",
  },
  orderStatus: {
    draft: "Draft",
    contested: "Contested",
    paid: "Paid",
    unpaid: "Unpaid",
    pending: "Pending",
    reviewed: "Reviewed",
    paymentProcessing: "Payment Processing",
    refunded: "Refunded",
    unknown: "Unknown",
  },
  receiptsList: {
    headings: {
      date: "Date",
      time: "Time",
      status: "Status",
      total: "Total",
    },
    action: {
      view: "View",
    },
    statusTooltip: {
      draft: "Draft - Order being processed by the system",
      paid: "Paid - Order has been processed and paid",
      paymentProcessing:
        "Payment Processing - Order has been processed and payment is under process",
      unpaid: "Unpaid - Order has been processed but payment failed",
      pending:
        "Pending - Order has been contested by shopper and is pending for review by the retailer",
      reviewed:
        "Reviewed - Order has been contested by shopper and the retailer has submitted the revision",
    },
  },
  receiptDetailScreen: {
    headerTitle: "Receipt Detail",
    subtotal: "Subtotal",
    taxBreakdown: {
      headers: {
        rate: "Tax %",
        netto: "Net",
        brutto: "Gross",
        tax: "Tax",
        total: "Total:",
      },
    },
    tax: "Tax",
    taxCRV: "CRV",
    total: "Total",
    processTime: "Process time",
    date: "Date",
    paymentStatus: "Payment Status",
    orderId: "Order ID",
    listOfProducts: "Products",
  },
  receiptDetailComplain: {
    reportProblem: "Report a problem with this receipt",
    submitReview: "Send for a review",
    confirmTitle: "Receipt sent",
    confirmButton: "Done",
    addMissingItem: "Add missing item",
    addMissingItemMessage: "Add a message (optional)",
    form: {
      email: {
        label: 'How can we contact you?',
        placeholder: 'Your email, eg. me@example.com',
        errors: {
          required: 'E-mail address is required.',
          isEmail: 'E-mail address is not valid.',
          inUse: 'E-mail address is in use.'
        }
      }
    }
  },
  receiptsScreen: {
    label: {
      contested: "IN REVIEW",
      draft: "DRAFT",
      empty: "EMPTY",
      paid: "PAID",
      pending: "DRAFT",
      refunded: "REFUNDED",
      reviewed: "REVISED",
      unknown: "Unknown",
      unpaid: "UNPAID",
      paymentProcessing: "PAYMENT PROCESSING",
    },
    message: {
      contested: "Contested message",
      draft: "Draft message",
      empty: "Empty message",
      paid: "Paid message",
      pending: "Pending message",
      refunded: "Refunded message",
      reviewed: "Reviewed message",
      unpaid: "Unpaid message",
      paymentProcessing: "Payment Processing message",
    },
    minutes: "minutes",
    hours: "hours",
    days: "days",
  },
  slideModal: {
    close: "Close",
    searchPlaceholder: "Search item",
  },
  receiptsHome: {
    receiptPortal: "Receipt Portal",
    selectPaymentType: "Select preferred method to find your receipt",
    cardBin: "First %{cardBinDigitsCount} digits of a card number (BIN/IIN)",
    last4: "Last four digits of your payment method",
    last4Apple: "Last four digits of your Apple Pay Number",
    last4Google: "Last four digits of your Google Pay Number",
    instructionApple:
      "Locate last four digits of your Apple Pay Card Number in your Apple Wallet",
    openWallet: "Open Wallet",
    instructionGoogle:
      "Locate last four digits of your Google Pay Card Number in your Wallet",
    instructionExternalId:
      "Find the payment reference number in your payment app or bank statement.",
    externalIdLabel: "Payment reference number",
    externalIdPlaceholder: "",
    expDate: "Expiration date",
    visitDate: "Date of your visit",
    submit: "Retrieve Receipt(s)",
    registerEmail: "Enter your email to subscribe to future receipts",
    registerEmailSuccess: "Email address updated successfully.",
    registerEmailFail:
      "Email address wasn't updated because there wasn't any receipts associated with provided credit card information and/or visit date.",
    unrecognizedError: "An unrecognized error occurred",
    inconclusiveCardInfo: "We can not find your receipt",
    optionalFieldPlaceholder: 'Optional',
    invalidCaptcha: "Invalid captcha, please try again",
    oasisApiError:
      "We could not connect to the receipts server. Please try again later.",
  },
};
