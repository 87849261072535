import { useFormik } from "formik";
import React, { PropsWithChildren, useContext, useMemo, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { translate } from "src/lib/i18n";
import { ReceiptFormValues } from "./types";

import dayjs from "dayjs";
import { count } from "ramda";
import BankStatementIcon from "src/assets/icons/BankStatamentIcon";
import Card from "src/assets/icons/Card";
import { Icon } from "src/assets/icons/icon";
import { DashLine } from "src/components/v2/Dashline";
import { RequestStatus } from "src/lib/api/types";
import { SearchType } from "src/lib/common/searchTypes";
import { config } from "src/lib/config";
import { MasterContext } from "src/lib/masterContext";
import { Label } from "../../components/form/label";
import { ErrorText, SuccessText, WarningText } from "../../components/form/text";
import { ApplePayInstruction, GooglePayInstructions } from "../Instructions";
import { buildReceiptSearchValidationSchema } from "./utils";

interface ReceiptsFormProps {
  emailStatus: RequestStatus;
  onSubmit: (values: ReceiptFormValues) => void;
}

export const ReceiptsForm: React.FC<PropsWithChildren<ReceiptsFormProps>> = ({
  emailStatus,
  onSubmit,
  children,
}) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { flavor, appConfig } = useContext(MasterContext);

  const validationSchema = useMemo(() => {
    return buildReceiptSearchValidationSchema(
      appConfig.requireEmailRegistration,
      appConfig.searchByCreditCardRequiresExpiration,
      appConfig.allowSearchingCreditCardByCardBin
        ? appConfig.cardBinDigitsCount
        : null
    );
  }, [
    appConfig.requireEmailRegistration,
    appConfig.searchByCreditCardRequiresExpiration,
    appConfig.allowSearchingCreditCardByCardBin,
    appConfig.cardBinDigitsCount,
  ]);

  const receiptsForm = useFormik({
    validateOnMount: true,
    initialValues: {
      customerPaymentInstrumentBin: "",
      customerPaymentInstrumentLast4: "",
      customerPaymentInstrumentExpDate: "",
      externalId: "",
      visitDate: dayjs().format("YYYY-MM-DD"),
      captchaToken: "",
      email: "",
    },
    validationSchema,
    onSubmit: (args: ReceiptFormValues) => {
      onSubmit(args);
      recaptchaRef?.current?.reset();
    },
  });

  const getLast4Message = (searchType: SearchType | undefined): string => {
    switch (searchType) {
      case "apple":
        return translate("receiptsHome.last4Apple");
      case "google":
        return translate("receiptsHome.last4Google");
      case "card":
      default:
        return translate("receiptsHome.last4");
    }
  };

  const isSearchMethodEnabled: Record<SearchType, boolean> = {
    card: true,
    apple: true,
    google: true,
    externalId: appConfig.allowSearchingByExternalId,
  };

  const numberOfSearchMethods = count(
    (isEnabled) => isEnabled,
    Object.values(isSearchMethodEnabled)
  );
  const searchButtonWidth = `${(100 / numberOfSearchMethods).toFixed(2)}%`;

  return (
    <div style={{ fontWeight: 500 }}>
      <Row className="justify-content-center">
        <Col sm={7} style={{ textAlign: "center" }}>
          <h2 className="mb-3">
            <b>{translate("receiptsHome.receiptPortal")}</b>
          </h2>
          {!receiptsForm.values.searchType && (
            <>
              <DashLine />
              <h5 className="my-3">
                {translate("receiptsHome.selectPaymentType")}
              </h5>
            </>
          )}
        </Col>
      </Row>
      <Form
        className="row justify-content-center"
        onSubmit={receiptsForm.handleSubmit}
      >
        <FormGroup row className="justify-content-center">
          <Col sm={7}>
            <ButtonGroup style={{ width: "100%", maxHeight: "68px" }}>
              {isSearchMethodEnabled.card && (
                <Button
                  style={{ width: searchButtonWidth, display: "flex" }}
                  outline={receiptsForm.values.searchType !== "card"}
                  onClick={() => {
                    receiptsForm.setFieldValue("searchType", "card");
                  }}
                >
                  <Card
                    color={
                      receiptsForm.values.searchType === "card"
                        ? "white"
                        : flavor.getMainColor()
                    }
                  />
                </Button>
              )}
              {isSearchMethodEnabled.apple && (
                <Button
                  style={{ width: searchButtonWidth }}
                  outline={receiptsForm.values.searchType !== "apple"}
                  onClick={() => {
                    receiptsForm.setFieldValue("searchType", "apple");
                  }}
                >
                  <Icon
                    icon="ApplePayIcon"
                    style={{
                      width: "100%",
                      height: "100%",
                      filter:
                        receiptsForm.values.searchType === "apple"
                          ? "invert()"
                          : "",
                    }}
                  />
                </Button>
              )}
              {isSearchMethodEnabled.google && (
                <Button
                  style={{ width: searchButtonWidth }}
                  outline={receiptsForm.values.searchType !== "google"}
                  onClick={() => {
                    receiptsForm.setFieldValue("searchType", "google");
                  }}
                >
                  <Icon
                    icon="GooglePayIcon"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Button>
              )}
              {isSearchMethodEnabled.externalId && (
                <Button
                  style={{ width: searchButtonWidth }}
                  outline={receiptsForm.values.searchType !== "externalId"}
                  onClick={() => {
                    receiptsForm.setFieldValue("searchType", "externalId");
                  }}
                >
                  <BankStatementIcon
                    color={
                      receiptsForm.values.searchType === "externalId"
                        ? "white"
                        : flavor.getMainColor()
                    }
                  />
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </FormGroup>
        {receiptsForm.values.searchType && (
          <>
            {receiptsForm.values.searchType === "apple" && (
              <ApplePayInstruction />
            )}
            {receiptsForm.values.searchType === "google" && (
              <GooglePayInstructions />
            )}
            {receiptsForm.values.searchType === "externalId" && (
              <Row className="justify-content-center">
                <Col sm={7} md={7}>
                  <p className="fw-bolder">
                    {translate("receiptsHome.instructionExternalId")}
                  </p>
                </Col>
              </Row>
            )}
            {receiptsForm.values.searchType === "card" &&
              appConfig.allowSearchingCreditCardByCardBin && (
                <FormGroup row className="justify-content-center">
                  <Label
                    for="input-cc-last-digits"
                    sm={3}
                    className="fw-bolder"
                    required
                  >
                    {translate("receiptsHome.cardBin", {
                      cardBinDigitsCount: appConfig.cardBinDigitsCount,
                    })}
                  </Label>
                  <Col sm={4}>
                    <Input
                      inputMode="numeric"
                      id="input-cc-bin"
                      pattern="\d+"
                      minLength={appConfig.cardBinDigitsCount}
                      maxLength={appConfig.cardBinDigitsCount}
                      placeholder={getCreditCardBinPlaceholder(
                        appConfig.cardBinDigitsCount
                      )}
                      name="customerPaymentInstrumentBin"
                      value={receiptsForm.values.customerPaymentInstrumentBin}
                      onChange={(e) => {
                        receiptsForm.handleChange(e);
                      }}
                      onBlur={receiptsForm.handleBlur}
                    />
                    {receiptsForm.errors.customerPaymentInstrumentBin &&
                    receiptsForm.touched.customerPaymentInstrumentBin ? (
                      <ErrorText>
                        {receiptsForm.errors.customerPaymentInstrumentBin}
                      </ErrorText>
                    ) : null}
                  </Col>
                </FormGroup>
              )}
            {(receiptsForm.values.searchType === "card" ||
              receiptsForm.values.searchType === "apple" ||
              receiptsForm.values.searchType === "google") && (
              <FormGroup row className="justify-content-center">
                <Label
                  for="input-cc-last-digits"
                  sm={3}
                  className="fw-bolder"
                  required
                >
                  {getLast4Message(receiptsForm.values.searchType)}
                </Label>
                <Col sm={4}>
                  <Input
                    id="input-cc-last-digits"
                    inputMode="numeric"
                    pattern="\d\d\d\d"
                    name="customerPaymentInstrumentLast4"
                    placeholder="e.g. 1234"
                    value={receiptsForm.values.customerPaymentInstrumentLast4}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      if (value.length < 5) {
                        receiptsForm.handleChange(e);
                      }
                    }}
                    onBlur={receiptsForm.handleBlur}
                    maxLength={4}
                  />
                  {receiptsForm.errors.customerPaymentInstrumentLast4 &&
                  receiptsForm.touched.customerPaymentInstrumentLast4 ? (
                    <ErrorText>
                      {receiptsForm.errors.customerPaymentInstrumentLast4}
                    </ErrorText>
                  ) : null}
                </Col>
              </FormGroup>
            )}
            {receiptsForm.values.searchType === "externalId" && (
              <FormGroup row className="justify-content-center">
                <Label
                  for="input-cc-last-digits"
                  sm={3}
                  className="fw-bolder"
                  required
                >
                  {translate("receiptsHome.externalIdLabel")}
                </Label>
                <Col sm={4}>
                  <Input
                    id="input-external-id"
                    inputMode="numeric"
                    name="externalId"
                    placeholder={translate(
                      "receiptsHome.externalIdPlaceholder"
                    )}
                    value={receiptsForm.values.externalId}
                    onChange={receiptsForm.handleChange}
                    onBlur={receiptsForm.handleBlur}
                  />
                  {receiptsForm.errors.externalId &&
                  receiptsForm.touched.externalId ? (
                    <ErrorText>
                      {receiptsForm.errors.externalId}
                    </ErrorText>
                  ) : null}
                </Col>
              </FormGroup>
            )}
            {receiptsForm.values.searchType === "card" &&
              appConfig.searchByCreditCardRequiresExpiration && (
                <FormGroup row className="justify-content-center">
                  <Label
                    for="input-exp-date"
                    sm={3}
                    className="fw-bolder"
                    required
                  >
                    {translate("receiptsHome.expDate")}
                  </Label>
                  <Col sm={4}>
                    <Input
                      id="input-exp-date"
                      name="customerPaymentInstrumentExpDate"
                      placeholder="MM/YY"
                      inputMode="numeric"
                      pattern="\d\d/\d\d"
                      value={
                        receiptsForm.values.customerPaymentInstrumentExpDate
                      }
                      onChange={receiptsForm.handleChange}
                      onBlur={receiptsForm.handleBlur}
                      mask="99/99"
                      tag={InputMask}
                    />
                    {receiptsForm.errors.customerPaymentInstrumentExpDate &&
                    receiptsForm.touched.customerPaymentInstrumentExpDate ? (
                      <ErrorText>
                        {receiptsForm.errors.customerPaymentInstrumentExpDate}
                      </ErrorText>
                    ) : null}
                  </Col>
                </FormGroup>
              )}
            <FormGroup row className="justify-content-center">
              <Label
                for="input-visit-date"
                sm={3}
                className="fw-bolder"
                required
              >
                {translate("receiptsHome.visitDate")}
              </Label>
              <Col sm={4}>
                <Input
                  id="input-visit-date"
                  name="visitDate"
                  type="date"
                  placeholder="DD/MM/YYYY"
                  value={receiptsForm.values.visitDate}
                  onChange={receiptsForm.handleChange}
                  onBlur={receiptsForm.handleBlur}
                />
                {receiptsForm.errors.visitDate &&
                receiptsForm.touched.visitDate ? (
                  <ErrorText>{receiptsForm.errors.visitDate}</ErrorText>
                ) : null}
              </Col>
            </FormGroup>
            <FormGroup row className="justify-content-center">
              <Label
                for="input-show-email"
                sm={3}
                className="fw-bolder"
                required={appConfig.requireEmailRegistration}
              >
                {translate("receiptsHome.registerEmail")}
              </Label>
              <Col sm={4}>
                <Input
                  id="input-email"
                  name="email"
                  type="email"
                  value={receiptsForm.values.email}
                  onChange={receiptsForm.handleChange}
                  onBlur={receiptsForm.handleBlur}
                  placeholder={
                    appConfig.requireEmailRegistration ? undefined : translate('receiptsHome.optionalFieldPlaceholder')
                  }
                />
                {receiptsForm.errors.email && receiptsForm.touched.email ? (
                  <ErrorText>{receiptsForm.errors.email}</ErrorText>
                ) : emailStatus === "success" ? (
                  <SuccessText>
                    {translate("receiptsHome.registerEmailSuccess")}
                  </SuccessText>
                ) : emailStatus === "error" ? (
                  <WarningText>
                    {translate("receiptsHome.registerEmailFail")}
                  </WarningText>
                ) : null}
              </Col>
            </FormGroup>
            <Row className="justify-content-center">
              <Col sm={7} md={7}>
                {children}
              </Col>
            </Row>
            {receiptsForm.values.captchaToken ? (
              <Row className="justify-content-center">
                <Col sm={3} md={7} style={{ padding: "20px 0" }}>
                  <Row>
                    <Button
                      type="submit"
                      disabled={!receiptsForm.isValid}
                      title={translate("receiptsHome.submit")}
                    >
                      {translate("receiptsHome.submit")}
                    </Button>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-center w-auto">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={config.RECAPTCHA_KEY}
                  onChange={(token: string | null) => {
                    receiptsForm.setFieldValue("captchaToken", token);
                  }}
                />
              </Row>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

function getCreditCardBinPlaceholder(digitsCount: number) {
  let digits = "123456789";
  let example = "";
  for (let i = 0; i < digitsCount; ++i) {
    example += digits[i % digits.length];
  }
  return `e.g. ${example}`;
}
