import React from "react";
import { color2 } from "../../styles";
import { AppConfig, IFeatureFlags } from "../../../../shared/interface";

export type CurrencyCode = "USD" | "EUR" | "PLN" | "GBP" | "CNY";

export const defaultFeatureFlags: IFeatureFlags = {
  // Common with CustomerAPP:
  taxEnabled: false,
  hideDraftProducts: true,
  productsTaxEnabled: true,
  isFirstData: false,
  isCompanyEnabled: false,
  isPhoneNumberEnabled: false,
  isAddressEnabled: false,
  aifiFooterText: true,
  isGroupShopping: false,
  hideProcessTime: true,
  isOrderDetailQrCodeEnabled: false,
  isStripeV2: false,
  isPrivacyPolicyEnabled: false,
  privacyPolicyLink: "",
  isDataProtectionEmailEnabled: false,
  dataProtectionEmail: "dataprotection@aifi.com",
  isKeycloakEnabled: false,
  isNPSEnabled: false,
  refreshQrcodeInterval: 60000,
  googleWebClientId: "",
  isFreedomPay: false,
  freedomPayBaseUrl: "",
  priceIncludesTax: false, // same as in cloud-api
  adyenEnvironmentType: "test",
  isAdyen: false,
  adyenClientKey: "",
  hideTaxBreakdown: false,
  hideCardBrand: false,

  // WebReceipt only:
  hidePoweredByAiFi: false,
  orderDetailsShowHeader: true,
  orderDetailsShowDetailsAtTheTop: false,
  orderDetailsShowProductsListTitle: false,
  orderDetailsShowFooterOrderId: true,
  contestingEnabled: true,
  downloadReceiptEnabled: true,
};

/**
 * Also prevents "optimizing" conditions out by transpiler that rely on process.env, which we
 * substitute after build!
 */
const readEnvBoolean = (name: string, defaultValue: boolean): boolean => {
  const value = process.env[name];
  if (!value) {
    return defaultValue;
  }
  if (value.toLowerCase() === "true") {
    return true;
  } else if (value.toLowerCase() === "false") {
    return false;
  } else {
    return defaultValue;
  }
};

export const getDefaultAppConfig = (): AppConfig => ({
  timezone: "",
  locale: "en",
  allowSearchingByExternalId: readEnvBoolean(
    "REACT_APP_ALLOW_SEARCHING_BY_EXTERNAL_ID",
    false
  ),
  allowSearchingCreditCardByCardBin: false,
  requireEmailRegistration: false,
  requireEmailRegistrationOnContest: false,
  cardBinDigitsCount: 6,
  searchByCreditCardRequiresExpiration: true,
  hideTaxBreakdown: false,
  hideCardBrand: false,
  receiptHeaderLogo: "",
  receiptStructureJson: null,
  currencyFormat: "symbol",
  messages: {
    footerPaid: "",
    footerInReview: "",
    footerReviewed: "",
    homeOrderNotReadyMsg:
      'Just left the store? Your receipt is being processed, please check back in a while.<br/>In case of any issues, reach out for support at <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
    errorReachOutSupportMsg:
      'In case of any issues, reach out for support at <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
  },
  referencePages: [],
});

export interface IFlavor {
  getIconColor(): string;
  getSpinnerColor(): string;
  getDashLineColor(): string;
  getFeatureFlags(): IFeatureFlags;
  getCircleLogo(): JSX.Element;
  getReceiptLogo(): JSX.Element;
  getPrimaryFont(): string;
  getPrimaryFontLight(): string;
  getPrimaryFontLightest(): string;
  getSecondaryFont(): string;
  getSecondaryFontBold(): string;
  formatDateTime(
    dateTime: string,
    timezone: string
  ): { date: string; time: string };
  formatTableDateTime(
    dateTime: string,
    timezone: string
  ): { date: string; time: string };
  getMainColor(): string;
}

export class DefaultFlavor implements IFlavor {
  getIconColor(): string {
    return color2.BLACK;
  }

  getSpinnerColor(): string {
    return color2.BLACK;
  }

  getDashLineColor(): string {
    return color2.BLACK;
  }

  getFeatureFlags(): IFeatureFlags {
    return defaultFeatureFlags;
  }

  getCircleLogo(): JSX.Element {
    return <></>;
  }

  getReceiptLogo(): JSX.Element {
    return this.getCircleLogo();
  }

  getPrimaryFont(): string {
    return "";
  }

  getPrimaryFontLight(): string {
    return "";
  }

  getPrimaryFontLightest(): string {
    return "";
  }

  getSecondaryFont(): string {
    return "";
  }

  getSecondaryFontBold(): string {
    return "";
  }

  formatDateTime(
    dateTime: string,
    timezone: string
  ): { date: string; time: string } {
    return { date: dateTime, time: "" };
  }

  formatTableDateTime(
    dateTime: string,
    timezone: string
  ): { date: string; time: string } {
    return this.formatDateTime(dateTime, timezone);
  }

  getMainColor(): string {
    return color2.BLACK;
  }
}
