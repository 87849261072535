import { useContext } from "react";
import { MasterContext } from "src/lib/masterContext";
import styled from "styled-components";

export const ReferencePagesNavigation = () => {
  const { appConfig } = useContext(MasterContext);

  if (appConfig.referencePages.length === 0) {
    return null;
  }

  return (
    <LinksContainer>
      {appConfig.referencePages.map(({ key, title, content }) => {
        let href;
        if (content.type === "link") {
          href = content.url;
        } else if (content.type === "text") {
          href = `/documents/${key}`;
        }

        return (
          <LinkWrapper key={key}>
            <Link key={key} href={href} target="_blank">
              {title}
            </Link>
          </LinkWrapper>
        );
      })}
    </LinksContainer>
  );
};

const Link = styled.a`
  text-decoration: none;
`;
const LinkWrapper = styled.span``;
const LinksContainer = styled.div`
  text-align: center;
  font-size: 12px;
  > ${LinkWrapper} + ${LinkWrapper}::before {
   content: "";
   border-left: 1px solid #e0e0e0;
   margin: 0 16px;
  }
}`;
