import { ITranslation } from "./types";

export const ja: ITranslation = {
  messageFallbacks: {
    homeOrderNotReadyMsg:
      'お店を出たばかりですか？領収書を処理中です。しばらくしてからもう一度ご確認ください。<br/>問題がある場合は、サポートにお問い合わせください。 <a style="white-space: nowrap;" href="mailto:support@aifi.com">support@aifi.com</a>',
    errorReachOutSupportMsg:
      '何か問題がありましたら、サポートにお問い合わせください。<a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
  },
  api: {
    E_FORCED_LOGOUT: "接続がタイムアウトしてログアウトされました。",
  },
  orderStatus: {
    draft: "ドラフト",
    contested: "レビュー中",
    paid: "支払済み",
    unpaid: "未払い",
    pending: "ドラフト",
    reviewed: "修正済み",
    paymentProcessing: "支払い処理中",
    refunded: "返金済み",
    unknown: "不明",
  },
  receiptsList: {
    headings: {
      date: "日付",
      time: "時間",
      status: "ステータス",
      total: "合計",
    },
    action: {
      view: "閲覧する",
    },
    statusTooltip: {
      draft: "ドラフト - システムによる解析処理中",
      paid: "支払済み - 解析処理が完了し、支払いが完了しました",
      paymentProcessing: "支払い処理中 – 解析処理が完了し、支払いを処理中です",
      unpaid: "未払い – オーダーは処理されましたが、支払いが失敗しました",
      pending:
        "レビュー中 -お客様から修正依頼が申し立てられ、運営者による確認を待っています",
      reviewed:
        "修正済み – お客様が決済修正を依頼し、運営者が修正を確定しました",
    },
  },
  receiptDetailScreen: {
    headerTitle: "支払い明細",
    subtotal: "小計",
    taxBreakdown: {
      headers: {
        rate: "税率",
        netto: "税抜",
        brutto: "税込",
        tax: "税",
        total: "合計：",
      },
    },
    tax: "税",
    taxCRV: "CRV",
    total: "計",
    processTime: "処理時刻",
    date: "日付",
    paymentStatus: "支払い状況",
    orderId: "注文ID",
    listOfProducts: "製品",
  },
  receiptDetailComplain: {
    reportProblem: "問題の報告",
    submitReview: "レビューの送信",
    confirmTitle: "送信されたレシート",
    confirmButton: "完了",
    addMissingItem: "不足している項目の追加",
    addMissingItemMessage: "メッセージの追加（任意）",
    form: {
      email: {
        label: "どうやって連絡できますか?",
        placeholder: "あなたのメールアドレス (例: me@example.com)",
        errors: {
          required: "メールアドレスが必要です。",
          isEmail: "無効なメールアドレスです。",
          inUse: "メールアドレスは既に使用されています。",
        },
      },
    },
  },
  receiptsScreen: {
    label: {
      contested: "レビュー中",
      draft: "ドラフト",
      empty: "空欄",
      paid: "支払い済",
      pending: "レビュー中",
      refunded: "返金済",
      reviewed: "修正済",
      unknown: "不明",
      unpaid: "未払い",
      paymentProcessing: "支払い処理中",
    },
    message: {
      contested: "コンテストメッセージ",
      draft: "下書きメッセージ",
      empty: "空のメッセージ",
      paid: "支払いメッセージ",
      pending: "保留中のメッセージ",
      refunded: "返金済みメッセージ",
      reviewed: "修正済みメッセージ",
      unpaid: "未払いメッセージ",
      paymentProcessing: "支払い処理中",
    },
    minutes: "分",
    hours: "時間",
    days: "日",
  },
  slideModal: {
    close: "閉じる",
    searchPlaceholder: "アイテムの検索",
  },
  receiptsHome: {
    receiptPortal: "レシートポータル",
    selectPaymentType: "ご使用になった決済方法をタップしてください",
    cardBin: "カード番号の最初の%{cardBinDigitsCount}桁(BIN/IIN)",
    last4: "クレジットカード番号の下4桁",
    last4Apple: " Apple Payのカード番号の下4桁",
    last4Google: " Google Pay のカード番号の下4 桁",
    instructionApple:
      " Apple Wallet内でのカード番号の下4桁はこちらでご確認下さい",
    openWallet: "ウォレットを開く",
    instructionGoogle:
      "ウォレット内でのカード番号の下4桁はこちらでご確認下さい",
    instructionExternalId:
      "支払いアプリまたは銀行取引明細書で支払い参照番号を見つけてください",
    externalIdLabel: "支払い参照番号",
    externalIdPlaceholder: "",
    expDate: "有効期限",
    visitDate: "ご来店日",
    submit: "領収書を検索",
    registerEmail:
      "メールアドレスをご入力いただくと、以降同じカードで店舗をご利用された際のレシートがメールで届きます",
    registerEmailSuccess: "メールアドレスが正常に更新されました.",
    registerEmailFail:
      "提供されたクレジットカード情報や訪問日に関連付けられたレシートがなかったため、メールアドレスは更新されませんでした.",
    unrecognizedError: "不明なエラーが発生しました",
    inconclusiveCardInfo: "領収書が見つかりません",
    optionalFieldPlaceholder: '任意',
    invalidCaptcha:
      "キャプチャが無効です。ページを更新してもう一度お試しください",
    oasisApiError:
      "サーバーに接続できませんでした。しばらくしてからもう一度お試しください。",
  },
};
