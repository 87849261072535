import { ScrollView, View } from "react-native";
import StyledScreen from "src/components/StyledScreen";
import { ReceiptBlockRouter } from "./blocks/ReceiptBlockRouter";
import { styles } from "./styles";
import { MasterContext } from "src/lib/masterContext";
import { useContext } from "react";
import { useReceiptOrder } from "../ReceiptContext";
import * as R from "ramda";
import { ReceiptStructureEntry } from "../../../../../web-receipt-api/src/lib/validateReceiptStructure";

export function ReceiptDetail() {
  const {
    featureFlags,
    appConfig: { receiptStructureJson },
  } = useContext(MasterContext);

  const order = useReceiptOrder();

  let structure: ReceiptStructureEntry[];
  if (!receiptStructureJson) {
    const legacyImpliedStructure: (ReceiptStructureEntry | null)[] = [
      { element: "Logo" },
      ...(featureFlags.orderDetailsShowHeader
        ? ([
            {
              element: "Text",
              style: "title",
              textAlign: "center",
              content: {
                type: "translation",
                translationKey: "receiptDetailScreen.headerTitle",
              },
            },
            { element: "EmptySpace" },
            { element: "DashLine" },
          ] satisfies ReceiptStructureEntry[])
        : []),
      featureFlags.orderDetailsShowDetailsAtTheTop
        ? { element: "OrderIdWithDate" }
        : null,
      featureFlags.orderDetailsShowHeader ? { element: "PaymentStatus" } : null,
      featureFlags.orderDetailsShowProductsListTitle
        ? {
            element: "Text",
            style: "title",
            content: {
              type: "translation",
              translationKey: "receiptDetailScreen.listOfProducts",
            },
          }
        : null,
      { element: "DashLine" },
      { element: "ProductsLines" },
      { element: "DashLine" },
      featureFlags.hideTaxBreakdown || R.isNil(order.taxBreakdownTableData)
        ? { element: "ChargeData" }
        : { element: "TaxBreakdown" },
      { element: "DashLine" },
      ...(!featureFlags.hideCardBrand
        ? ([
            { element: "CardData" },
            { element: "DashLine" },
          ] satisfies ReceiptStructureEntry[])
        : []),
      !featureFlags.orderDetailsShowDetailsAtTheTop
        ? { element: "DateTime" }
        : null,
      !featureFlags.orderDetailsShowDetailsAtTheTop &&
      !featureFlags.hideProcessTime
        ? { element: "ProcessTime" }
        : null,
      featureFlags.orderDetailsShowFooterOrderId
        ? { element: "FooterOrderIdBlock" }
        : null,
      { element: "FooterText" },
      { element: "ExtraText" },
      featureFlags.downloadReceiptEnabled
        ? { element: "DownloadReceipt" }
        : null,
      { element: "ReportProblem" },
      !featureFlags.hidePoweredByAiFi ? { element: "PoweredByAifi" } : null,
      { element: 'ReferencePagesNavigation' },
      { element: "EmptySpace", size: "sm" },
    ];

    structure = legacyImpliedStructure.filter(
      (block): block is NonNullable<typeof block> => block !== null
    );
  } else {
    structure = JSON.parse(receiptStructureJson) as ReceiptStructureEntry[];
  }

  return (
    <StyledScreen>
      <ScrollView style={styles.scroll}>
        <View style={styles.content}>
          {structure.map((block, index) => (
            <ReceiptBlockRouter block={block} key={index} />
          ))}
        </View>
      </ScrollView>
    </StyledScreen>
  );
}
