import { useContext } from "react";
import { useParams } from "react-router-dom";
import { MasterContext } from "src/lib/masterContext";
import styled from "styled-components";

export const DocumentViewer = () => {
  const { documentKey } = useParams();
  const { appConfig } = useContext(MasterContext);

  const document = appConfig.referencePages.find(
    (page) => page.key === documentKey
  );
  if (!document || document.content.type !== "text") {
    return <h1>Document not found</h1>;
  }

  return (
    <Container>
      <h1>{document.title}</h1>
      {document.content.text.split("\n").map((par, index) => {
        return <Paragraph>{par}</Paragraph>;
      })}
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`;
const Paragraph = styled.p`
  white-space: pre-wrap;
`;
