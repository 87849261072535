import { PoweredByAiFi } from "src/assets/icons/PoweredByAiFi";
import { ReferencePagesNavigation } from "../ReferencePagesNavigation";
import styled from "styled-components";

export const Footer = () => {
  return (
    <FooterSection>
      <PoweredByAiFi />
      <ReferencePagesNavigation />
    </FooterSection>
  );
};

const FooterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  gap: 16px;
`;
