import { observer } from "mobx-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ScrollView, View } from "react-native";
import ProductSearchModal from "src/components/v21/ProductSearchModal";
import { useApi } from "src/lib/ApiProvider";
import { MasterContext } from "src/lib/masterContext";
import { z } from "zod";

import { BackButton } from "../../../components/BackButton";
import CenterActivityIndicator from "../../../components/CenterActivityIndicator";
import StyledScreen from "../../../components/StyledScreen";
import { Button } from "../../../components/v2/Button";
import { QuantityButton } from "../../../components/v2/QuantityButton";
import ReceiptDetailSection from "../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../lib/i18n";
import { getTimeSpent } from "../../../lib/utils";
import ReceiptFooter from "../ReceiptDetail/ReceiptFooter";
import ReceiptHeader from "../ReceiptDetail/ReceiptHeader";
import { styles } from "../ReceiptDetail/styles";
import { styles as styles2 } from "./styles";
import { useReceiptOrder } from "../ReceiptContext";
import { Input } from "reactstrap";
import { Label } from "../../../components/form/label";
import { Input as TextInput } from "src/components/v2/TextInput";
import axios from "axios";
import { ErrorText } from "src/components/form/text";
import { RequestStatus } from "src/lib/api/types";
import { Spinner } from "src/components/Spinner";

const CONTEST_FEEDBACK_MAX_LEN = 140;

type ProductListItem = Omit<ApiLineItem, "status" | "totalPrice">;

interface IReceiptDetailComplain {
  onBack: () => void;
  onContestSuccess: () => void;
}

interface CustomerForContest {
  maskedEmail: string | null;
}

const ReceiptDetailComplain = (props: IReceiptDetailComplain) => {
  const [requestStatus, setRequestStatus] =
    useState<Extract<RequestStatus, "loading" | "success" | "error">>(
      "loading"
    );

  const [customerDetails, setCustomerDetails] =
    useState<CustomerForContest | null>(null);

  const { authApi } = useApi();
  const order = useReceiptOrder();

  const orderIdRef = useRef<string | null>(order.id);
  orderIdRef.current = order.id;

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      setRequestStatus("loading");
      const orderId = order.id;
      try {
        const response = await authApi.get<CustomerForContest>(
          `api/v2/orders/${orderId}/customer`
        );
        if (orderIdRef.current !== orderId) {
          return;
        }
        setCustomerDetails(response.data);
        setRequestStatus("success");
      } catch {
        setCustomerDetails(null);
        setRequestStatus("error");
      }
    };

    fetchCustomerDetails();
  }, [authApi, order.id]);

  if (requestStatus === "loading") {
    return <Spinner />;
  }

  if (requestStatus === "success" || requestStatus === "error") {
    // If error, assume there is no customer data and go forwards.
    return (
      <ReceiptDetailComplainInner
        {...props}
        customerMaskedEmail={customerDetails?.maskedEmail ?? null}
      />
    );
  }

  const unknownRequestStatus: never = requestStatus;
  throw new Error(`Unknown request status: ${unknownRequestStatus}`);
};

const ReceiptDetailComplainInner = ({
  onBack,
  onContestSuccess,
  customerMaskedEmail,
}: IReceiptDetailComplain & {
  customerMaskedEmail: string | null;
}) => {
  const { authApi } = useApi();
  const {
    flavor,
    featureFlags: { hideProcessTime },
    appConfig: { timezone, requireEmailRegistrationOnContest },
  } = useContext(MasterContext);

  const [showSearchProduct, setShowSearchProduct] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState<ProductListItem[]>([]);
  const [customerEmail, setCustomerEmail] = React.useState("");
  const [customerEmailFieldError, setCustomerEmailFieldError] = React.useState<
    string | null
  >(null);
  const [customerMessage, setCustomerMessage] = React.useState("");
  const [itemDelta, setItemDelta] = React.useState<
    Record<string, ProductListItem>
  >({});
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const order = useReceiptOrder();
  const created = flavor.formatDateTime(order.draftCreatedAt, timezone);
  const processTime = order
    ? getTimeSpent(order.draftCreatedAt, order.processedAt)
    : "";

  const handleItemQuantityChange = React.useCallback(
    (item: ProductListItem) => (quantity: number) => {
      setItemDelta({
        ...itemDelta,
        [item.id]: {
          ...item,
          quantity,
        },
      });
    },
    [itemDelta]
  );

  const handleItemAdd = React.useCallback(
    (product: ApiProductsDetail) => {
      if (!itemDelta[product.id]) {
        setItemDelta({
          ...itemDelta,
          [product.id]: {
            id: product.id,
            image: product.thumbnail,
            name: product.name,
            rin: product.rin,
            quantity: 1,
          },
        });
      } else {
        setItemDelta({
          ...itemDelta,
          [product.id]: {
            ...itemDelta[product.id],
            quantity: itemDelta[product.id].quantity + 1,
          },
        });
      }
      setShowSearchProduct(false);
    },
    [itemDelta]
  );

  React.useEffect(() => {
    const itemsChanged = order.lineItems.map((item) => ({
      ...item,
      quantity: itemDelta[item.id]
        ? itemDelta[item.id].quantity
        : item.quantity,
    }));

    const itemsAdded = Object.entries(itemDelta)
      .filter(([id]) => !order.lineItems.find((x) => id === x.id))
      .map(([, item]) => item);

    setItems([...itemsChanged, ...itemsAdded]);
  }, [itemDelta, order.lineItems]);

  React.useEffect(() => {
    const hasQuantityChanged =
      order.lineItems.some(
        (item) =>
          itemDelta[item.id] && item.quantity !== itemDelta[item.id].quantity
      ) ||
      Object.keys(itemDelta).filter(
        (id) => !order.lineItems.some((x) => x.id === id)
      ).length > 0;

    setIsFormChanged(!!customerMessage || hasQuantityChanged);
  }, [customerMessage, itemDelta, order.lineItems]);

  const handleSubmitReview = React.useCallback(() => {
    setCustomerEmailFieldError(null);

    const trimmedEmail = customerEmail.trim();
    if (customerMaskedEmail === null && requireEmailRegistrationOnContest) {
      if (trimmedEmail.length === 0) {
        setCustomerEmailFieldError(
          translate("receiptDetailComplain.form.email.errors.required")
        );
        return;
      }
    }

    setIsLoading(true);

    const contestedReceiptData = {
      originalReceipt: order.lineItems.map((i) => ({
        rin: i.rin,
        name: i.name,
        quantity: i.quantity,
      })),
      contestedReceipt: Object.values(itemDelta).map((item) => ({
        rin: item.rin,
        name: item.name,
        quantity: item.quantity,
      })),
      customerEmail: trimmedEmail.length > 0 ? trimmedEmail : undefined,
      customerMessage,
    };

    authApi
      .post(`/api/v2/orders/${order.graphQlId}/contest`, contestedReceiptData)
      .then((response) => {
        setIsLoading(false);
        onContestSuccess();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data
          ) {
            const errorSchema = z.object({
              message: z.array(
                z.object({
                  property: z.string(),
                  constraints: z.record(z.string()),
                })
              ),
            });
            const parsed = errorSchema.safeParse(error.response.data);
            if (parsed.success) {
              for (const message of parsed.data.message) {
                if (message.property === "customerEmail") {
                  const errorMessage = Object.entries(message.constraints)
                    .map(([constraint, error]) => {
                      switch (constraint) {
                        case "required":
                          return translate(
                            "receiptDetailComplain.form.email.errors.required"
                          );
                        case "isEmail":
                          return translate(
                            "receiptDetailComplain.form.email.errors.isEmail"
                          );
                        case "inUse":
                          return translate(
                            "receiptDetailComplain.form.email.errors.inUse"
                          );
                        default:
                          return error;
                      }
                    })
                    .join(" ");
                  setCustomerEmailFieldError(errorMessage);
                }
              }
            }
          }
        }
        console.error("Error while submitting contest data", error);
        setIsLoading(false);
      });
  }, [
    authApi,
    requireEmailRegistrationOnContest,
    customerEmail,
    customerMessage,
    itemDelta,
    onContestSuccess,
    order.graphQlId,
    order.lineItems,
    customerMaskedEmail,
  ]);

  const dateAndTimeData = [
    {
      title: translate("receiptDetailScreen.date"),
      value: `${created.date} ${created.time}`,
    },
  ];

  if (!hideProcessTime) {
    dateAndTimeData.push({
      title: translate("receiptDetailScreen.processTime"),
      value: processTime,
    });
  }

  return order ? (
    <StyledScreen>
      <ScrollView style={styles.scroll}>
        <div
          onClick={onBack}
          style={{
            position: "absolute",
            top: "1rem",
            left: "0.5rem",
            zIndex: 1,
          }}
        >
          <BackButton />
        </div>
        <ReceiptHeader />
        <View style={styles2.itemsWrapper}>
          {items.map((product) => {
            return (
              <QuantityButton
                key={product.id}
                trailingText={product.name}
                quantity={product.quantity}
                onChange={handleItemQuantityChange(product)}
              />
            );
          })}
        </View>
        <Button
          style={styles.submitButton}
          title={translate("receiptDetailComplain.addMissingItem")}
          onPress={() => {
            setShowSearchProduct(true);
          }}
        />
        <View style={styles2.notesWrapper}>
          <TextInput
            placeholder={translate(
              "receiptDetailComplain.addMissingItemMessage"
            )}
            style={styles2.notes}
            multiline={true}
            onChangeText={(value) => {
              setCustomerMessage(value);
            }}
            value={customerMessage}
            maxLength={CONTEST_FEEDBACK_MAX_LEN}
          />
        </View>
        <View style={styles2.emailField}>
          <Label
            for="input-show-email"
            className="fw-bolder"
            required={
              // If email is already provided, then it's not required anymore
              customerMaskedEmail === null && requireEmailRegistrationOnContest
            }
          >
            {translate("receiptDetailComplain.form.email.label")}
          </Label>
          <Input
            id="input-email"
            name="email"
            type="email"
            value={customerEmail}
            onChange={(event) => {
              setCustomerEmail(event.target.value);
            }}
            placeholder={
              customerMaskedEmail ??
              translate("receiptDetailComplain.form.email.placeholder")
            }
          />
          {customerEmailFieldError !== null && (
            <ErrorText>{customerEmailFieldError}</ErrorText>
          )}
        </View>
        <ReceiptDetailSection data={dateAndTimeData} />
        <ReceiptFooter
          loading={isLoading}
          submitText={translate("receiptDetailComplain.submitReview")}
          showSubmit={true}
          onSubmit={handleSubmitReview}
          isSubmitDisabled={!isFormChanged}
        />
      </ScrollView>
      {showSearchProduct && (
        <ProductSearchModal
          onDismiss={() => setShowSearchProduct(false)}
          onAdd={handleItemAdd}
        />
      )}
    </StyledScreen>
  ) : (
    <CenterActivityIndicator />
  );
};

export default observer(ReceiptDetailComplain);
