import React, { FC } from "react";

import { MasterContext } from "./index";
import { useMasterContext } from "../hooks/useMasterContext";

import { flavorName } from "../config";

export const MasterSettingsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { appConfig, featureFlags, flavor, setFlavor, setStoreData } =
    useMasterContext(flavorName);

  if (!appConfig) {
    return <div aria-label='Loading' />
  }

  return (
    <MasterContext.Provider
      value={{
        appConfig,
        featureFlags,
        flavor,
        setFlavor,
        setStoreData,
      }}
    >
      {children}
    </MasterContext.Provider>
  );
};
